// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";

// vuex/store
import { createNamespacedHelpers } from "vuex";
import {
  contextMenuRecordOperation,
  findRecordOperation,
  recordOperation,
  recordType,
  treeInsertOptions
} from "@/model/record/recordModel";
import {
  fullRecordVersionName,
  isAncestorMeeting,
  isBackupMaterial,
  isDirectUploadAllowed
} from "@/services/record/recordService";
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";
import {
  agendaItemOperation,
  findAgendaItemOperation
} from "@/model/agenda/item/agendaItemModel";
import { findImageOperation, imageOperation } from "@/model/image/imageModel";
import { notImplementedMethod } from "@/services/error/errorMessages";
import { insertFileOption } from "@/model/record/fileModel";
import { UploadOptionModel } from "@/model/record/checkInModel";
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { userSettingsMixin } from "@/mixins/shared/base/settings/userSettingsMixin";
import { iconExport, iconMenu, iconProjects } from "@/design/icon/iconConst";
import { moduleNames } from "@/model/solution/moduleModel";

const { mapGetters } = createNamespacedHelpers("solution");
const { mapActions } = createNamespacedHelpers("agenda");

/**
 * moduleItemsDrawerMixin Contains options (adhere to the DRY principle) &
 * code re-usability for Drawer components
 */
export const moduleItemsDrawerMixin = {
  mixins: [
    appMixin,
    baseComponentMixin,
    menuItemSettingsMixin,
    moduleItemNavigatable,
    snackbarableMixin,
    buttonSettingsMixin,
    userSettingsMixin
  ],
  components: {
    CoreNavigationDrawer: () =>
      import("@/components/shared/core/drawers/CoreNavigationDrawer"),
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    BaseTooltipIcon: () =>
      import("@/components/shared/base/icon/BaseTooltipIcon"),
    BaseNavList: () => import("@/components/shared/base/BaseNavList"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    RecordBadgeIcon: () => import("@/components/record/RecordBadgeIcon"),
    SearchTextField: () =>
      import("@/components/shared/base/BaseSearchTextField"),
    MoveRecordDialog: () =>
      import("@/components/shared/core/dialogs/MoveRecordDialog"),
    CopyRecordDialog: () =>
      import("@/components/shared/core/dialogs/CopyRecordDialog"),
    BatchMoveRecordsDialog: () =>
      import("@/components/shared/core/dialogs/BatchMoveRecordsDialog"),
    DeleteRecordDialog: () =>
      import("@/components/shared/core/dialogs/DeleteRecordDialog"),
    AgendaItemReferDialog: () =>
      import("@/components/agenda/dialogs/AgendaItemReferDialog.vue"),
    AgendaItemDeferDialog: () =>
      import("@/components/agenda/dialogs/AgendaItemDeferDialog.vue"),
    UploadLocalFileDialog: () =>
      import("@/components/shared/core/dialogs/UploadLocalFileDialog"),
    PasteShortcutRecordDialog: () =>
      import("@/components/shared/core/dialogs/PasteShortcutRecordDialog"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay"),
    BaseButton: () => import("@/components/shared/base/BaseButton"),
    BatchMoveItemsDialog: () =>
      import("@/components/shared/core/dialogs/BatchMoveItemsDialog"),
    SubmitRecallAgendaItemsDialog: () =>
      import("@/components/shared/core/dialogs/SubmitRecallAgendaItemsDialog")
  },
  data() {
    return {
      searchDrawerItemName: "",
      sourceRecord: undefined,
      destinationRecord: undefined,
      visibleMoveRecordDialog: false,
      visibleCopyRecordDialog: false,
      visibleDialogDelete: false,
      selectedRecord: undefined,
      agendaItemTemplates: [],
      visibleReferDialog: false,
      visibleDeferDialog: false,
      draggedFile: undefined,
      visibleUploadLocalFileDialog: false,
      visibleDialogPasteShortcut: false,
      batchRecordList: [],
      batchMoveOperation: undefined,
      visibleBatchMoveRecordsDialog: false,
      visibleBatchMoveItemsDialog: false,
      visibleSubmitRecallAgendaItemsDialog: false,
      isRecallOperation: true,

      //
      // nodes
      //
      displayRecordOptions: [10, 20, 50, 100],
      expandedRecordChildren: 0,
      loading: false,
      startIndex: 1,
      iconMenu: iconMenu,
      iconExport: iconExport,
      iconProjects: iconProjects,
      moduleName: moduleNames,
      serverSearchPerformed: false,
      insertTreePosition: -1,
      isMoveByPosition: false
    };
  },
  computed: {
    ...mapGetters({
      usesSidebarImage: "usesSidebarImage",
      enableMiniDrawer: "enableMiniDrawer"
    }),
    /**
     * Get whether drawer is Mini
     * Abstract computed
     * @return {boolean}
     */
    drawerMini: () => false,

    /**
     * Get Module Items
     * Abstract computed
     * @returns {{any}[]}
     */
    items: () => [],

    /**
     * Get item Count
     * @return {number|number}
     */
    itemCount() {
      return this?.items?.length ?? 0;
    },

    /**
     * Get selected module item id
     * Abstract computed
     * @return {number}
     */
    selectedModuleItemId: () => -1,

    /**
     * Indicate whether any Module Item can be selected
     * @return {boolean}
     */
    hasSelectableModuleItem() {
      return this.defaultSelectModuleItemId >= 0;
    },

    /**
     * suggested Select Module Item id
     * @return {Number|number|undefined}
     */
    defaultSelectModuleItemId() {
      return undefined;
    },

    /**
     * drawer Icon Color Class
     * @return {undefined|string|*}
     */
    drawerIconColorClass() {
      return this.usesSidebarImage ? undefined : this.menuItemColorClass;
    },

    /**
     * Fetch More Records Tooltip
     * @return {string}
     */
    fetchRecordsTooltip() {
      return `Fetch next ${this.recordsFetchCount} records`;
    },

    /**
     * Compute whether Load Button be enabled
     * @return {boolean}
     */
    enableDisplayMore() {
      return this.serverSearchPerformed
        ? false
        : !!this.items?.length &&
            this.items?.length !== (this.expandedRecordChildren ?? -1);
    }
  },
  methods: {
    ...mapActions({
      getAgendaItemTemplates: "getAgendaItemTemplates"
    }),

    /**
     * Abstract Update Record List By Id
     * @param {{type:string, records:Array}} payload
     */
    updateRecordListById(payload) {
      console.warn(`Abstract  updateRecordListById() id:`, payload);
    },

    /**
     * Submit Meeting Agenda Items
     * @param id
     * @return {undefined}
     */
    submitMeetingAgendaItems(id) {
      console.warn(`Abstract  submitMeetingAgendaItems() payload:`, id);
      return undefined;
    },

    /**
     * Submit Section Agenda Items
     * @param id
     * @return {undefined}
     */
    submitSectionAgendaItems(id) {
      console.warn(`Abstract  submitSectionAgendaItems() payload:`, id);
      return undefined;
    },

    /**
     * Recall Meeting Agenda Items
     * @param id
     * @return {undefined}
     */
    recallMeetingAgendaItems(id) {
      console.warn(`Abstract  recallMeetingAgendaItems() payload:`, id);
      return undefined;
    },

    /**
     * Recall Section Agenda Items
     * @param id
     * @return {undefined}
     */
    recallSectionAgendaItems(id) {
      console.warn(`Abstract  recallSectionAgendaItems() payload:`, id);
      return undefined;
    },

    /**
     * Get Selected Module Item id local storage
     * Abstract method
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId() {
      console.warn(
        "Not implemented Abstract method: getStoredSelectedModuleItemId() "
      );
    },

    /**
     * Move Record
     * Abstract method
     * @param {{recordId:Number,destinationId:Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    moveRecord(payload) {
      console.warn("Not implemented Abstract method: moveRecord() ", payload);
    },

    /**
     * Move Meeting Contents
     * Abstract method
     * @param {{recordId:Number,destinationId:Number,insertTreePosition: Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    moveMeetingContents(payload) {
      console.warn(
        "Not implemented Abstract method: moveMeetingContents() ",
        payload
      );
    },

    /**
     * Move Backup Material
     * Abstract method
     * @param {{recordId:Number,destinationId:Number,insertTreePosition: Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    moveBackupMaterial(payload) {
      console.warn(
        "Not implemented Abstract method: moveBackupMaterial() ",
        payload
      );
    },

    /**
     * Copy Record
     * Abstract method
     * @param {{recordId:Number,destinationId:Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    copyRecord(payload) {
      console.warn("Not implemented Abstract method: copyRecord() ", payload);
    },

    /**
     * Copy Record Shortcut
     * Abstract method
     * @param {{recordId:Number,recordParentId:Number,destinationId:Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    copyRecordShortcut(payload) {
      console.warn(
        "Not implemented Abstract method: copyRecordShortcut() ",
        payload
      );
    },

    /**
     * Move Record Shortcut
     * Abstract method
     * @param {{recordId:Number,recordParentId:Number,destinationId:Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    moveRecordShortcut(payload) {
      console.warn(
        "Not implemented Abstract method: moveRecordShortcut() ",
        payload
      );
    },

    /**
     * Paste Record Shortcut
     * Abstract method
     * @param {{recordId:Number,destinationId:Number}} payload
     * @return {Promise<{agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], fieldValues: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], flyingFields: {id: number, sequence: number}[], extension: string, checkOutExtension: string, version: number, versionDate: string, versionOwner: string, pageCount: number, fileSize: number, comments: string, id: number, name: string, parentId: number, ancestor: {id: number, name: string, categoryId: number, recordType: {id: number, name: string}, code: number, flags: number, createdBy: {id: number, name: string}, updatedBy: {id: number, name: string}, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isHidden: boolean, isLocked: boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean}, categoryId: number, stateId: number, stateOwnerId: number, state: string, recordTypeId: number, recordType: string, createdBy: string, updatedBy: string, owner: string, creationDate: string, modificationDate: string, flags: number, children: number, isDraft: boolean, isReadOnly: boolean, isLocked: boolean, isDeleted: boolean, isComposite: boolean, isLink: boolean, localFile: {hasFile: boolean, isModified: boolean, pageCount: number, extension: {type: number, extensions: string[], description: string}}, searchFields: {name: string, fieldDataType: number, fieldDataTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}[], id: number, value: string}[], operations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[], fields: string[]}>}
     */
    pasteRecordShortcut(payload) {
      console.warn(
        "Not implemented Abstract method: pasteRecordShortcut() ",
        payload
      );
    },

    /**
     * Delete Record
     * Abstract method
     * @param  record
     */
    deleteRecord(record) {
      console.warn("Not implemented Abstract method: deleteRecord() ", record);
    },

    /**
     * Delete Record Shortcut
     * Abstract method
     * @param {{id:Number, parentId: Number}} payload
     */
    deleteRecordShortcut(payload) {
      console.warn(
        "Not implemented Abstract method: deleteRecordShortcut() ",
        payload.id
      );
    },

    /**
     * Abstract Refer Agenda Item
     * @param {{sourceId:Number, destinationId:Number, option:Number, index:Number}} payload
     * @return {Promise<record>}
     */
    async referAgendaItem(payload) {
      console.warn(
        `Abstract ${this.$options.name}.referAgendaItem() called. payload:`,
        payload
      );
      return undefined;
    },

    /**
     * Abstract Defer Agenda Item
     * @param {{sourceId:Number, destinationId:Number, option:Number, index:Number}} payload
     * @return {Promise<record>}
     */
    async deferAgendaItem(payload) {
      console.warn(
        `Abstract ${this.$options.name}.deferAgendaItem() called. payload:`,
        payload
      );
      return undefined;
    },

    /**
     * Abstract upload File
     * @param {{file: File, id: (number|number), uploadFileModel: {Extension: (string|undefined), Draft: boolean, InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}, Comments: string, Enqueue: boolean}}} payload
     * @return {Promise<void>}
     */
    async uploadFile(payload) {
      console.warn(`Not implemented uploadFile() payload:`, payload);
    },

    /**
     * Abstract Check In File
     * @param {{file: File, id: (number|number), uploadFileModel: {Extension: (string|undefined), Draft: boolean, InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}, Comments: string, Enqueue: boolean}}} payload
     */
    checkInFile(payload) {
      throw notImplementedMethod(`checkInFile() payload: ${payload}`);
    },

    /**
     * Abstract Determines whether Module Item exists by its id
     * @param {Number|number} id
     * @return {Boolean|boolean}
     */
    existsModuleItem(id) {
      console.warn(`Abstract existsModuleItem() id:`, id);
      return false;
    },

    /**
     * can Select Module Item
     * @param {number} itemId
     * @return {boolean}
     */
    canSelectModuleItem(itemId) {
      return this.hasSelectableModuleItem && this.existsModuleItem(itemId);
    },

    /**
     * Abstract Set Drawer Mini
     * @param {boolean} drawerMini
     */
    setDrawerMini(drawerMini) {
      console.warn(`Abstract setDrawerMini() drawerMini:`, drawerMini);
    },

    /**
     * Abstract Set Moved Record
     * @param {{type:string, record:Object}} payload
     */
    setMovedRecord(payload) {
      console.warn(`Abstract  setMovedRecord() payload:`, payload);
    },

    /**
     * Abstract Set Moved Records (Batch)
     * @param {{type:string, records:Array}} payload
     */
    setMovedRecords(payload) {
      console.warn(`Abstract  setMovedRecords() payload:`, payload);
    },

    /**
     *  Abstract Set Local Uploaded File
     * @param {File} file
     */
    setLocalUploadedFile(file) {
      console.warn(`Abstract  setLocalUploadedFile() payload:`, file);
    },

    /**
     * Toggle Drawer (Mini/Maxi)
     */
    toggleMiniDrawer() {
      this.setDrawerMini(!this.drawerMini);
    },

    /**
     * ensure Selected Item
     * @return {Promise<void>}
     */
    async ensureSelectedItem() {
      try {
        console.log(
          `${this.$options.name}.ensureSelectedItem() this.$router.currentRoute:`,
          this.$router.currentRoute
        );

        if (this.$router.currentRoute.name !== this.appModuleName) {
          return;
        }

        console.log(
          `${this.$options.name}.ensureSelectedItem() this.selectedModuleItemId:`,
          this.selectedModuleItemId
        );

        if (!this.hasSelectableModuleItem) {
          console.warn(
            `Cannot Select any ${this.appModuleName}. No selectable ${this.appModuleName}; item Count:`,
            this.itemCount
          );
          return;
        }

        console.log(
          `${this.$options.n}.ensureSelectedItem() this.selectedModuleItemId:`,
          this.selectedModuleItemId
        );

        // Store preserved, ensure Selected Item by navigating To Module Item
        if (this.existsModuleItem(this.selectedModuleItemId)) {
          if (this.canNavigateToModuleItem(this.selectedModuleItemId)) {
            await this.navigateToModuleItem(this.selectedModuleItemId);
            return;
          }
        }

        // Store not preserved, so load from local store
        let id = this.getStoredSelectedModuleItemId();
        if (!this.existsModuleItem(id)) {
          id = this.defaultSelectModuleItemId;
          if (!this.existsModuleItem(id)) {
            console.warn(
              `Cannot navigate to ${this.appModuleName}. Default ${this.appModuleName} id: ${id}`
            );
            return;
          }
        }

        if (this.canNavigateToModuleItem(id)) {
          console.log(`Going to select ${this.appModuleName} item by id:`, id);
          await this.navigateToModuleItem(id);
        } else {
          console.warn(`Cannot navigate to ${this.appModuleName} by id:`, id);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async loadAgendaSectionItemTemplates(sectionId) {
      try {
        this.agendaItemTemplates =
          (await this.getAgendaItemTemplates(sectionId))?.data ?? [];
      } catch (e) {
        this.showSnackbarError(e.toString(), true);
      }
    },

    /**
     * Event is called when a dragged record is dropped on another record
     * @param {{draggedRecord:Object, destinationRecord:Object, insertPosition:number}}event
     * @return {Promise<void>}
     */
    async onRecordMoved(event) {
      try {
        console.log(`${this.$options.name} onRecordMoved() event:`, event);
        const draggedRecord = event.draggedRecord;
        const destinationRecord = event.destinationRecord;
        const insertPosition = event?.insertPosition ?? -1;

        await this.prepareMoveRecordDialog(
          draggedRecord,
          destinationRecord,
          insertPosition
        );
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Prepare Data and open Move Record Dialog
     * @param sourceRecord
     * @param destinationRecord
     * @param insertPosition
     */
    async prepareMoveRecordDialog(
      sourceRecord,
      destinationRecord,
      insertPosition = -1
    ) {
      try {
        this.sourceRecord = sourceRecord;
        this.destinationRecord = destinationRecord;
        if (this.sourceRecord.recordTypeId === recordType.ITEM) {
          const sectionId =
            this.destinationRecord.recordTypeId === recordType.SECTION
              ? this.destinationRecord.id
              : this.destinationRecord.ancestor.id;

          await this.loadAgendaSectionItemTemplates(sectionId);
          this.visibleDeferDialog = true;
          return;
        }

        this.isMoveByPosition =
          (isAncestorMeeting(this.sourceRecord) ||
            isBackupMaterial(this.sourceRecord)) &&
          insertPosition !== -1;

        this.insertTreePosition = this.isMoveByPosition
          ? insertPosition
          : treeInsertOptions.firstChild;

        this.visibleMoveRecordDialog = true;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Prepare Data and open Copy Record Dialog
     * @param sourceRecord
     * @param destinationRecord
     */

    async prepareCopyRecordDialog(sourceRecord, destinationRecord) {
      try {
        this.sourceRecord = sourceRecord;
        this.destinationRecord = destinationRecord;
        if (this.sourceRecord.recordTypeId === recordType.ITEM) {
          const sectionId =
            this.destinationRecord.recordTypeId === recordType.SECTION
              ? this.destinationRecord.id
              : this.destinationRecord.ancestor.id;

          await this.loadAgendaSectionItemTemplates(sectionId);
          this.visibleReferDialog = true;
          return;
        }
        this.visibleCopyRecordDialog = true;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Prepare Dialog to move records in Batch
     * @param recordList
     * @param destinationRecord
     * @param moveOperation
     * @return {Promise<void>}
     */
    async prepareBatchMoveRecordsDialog(
      recordList,
      destinationRecord,
      moveOperation
    ) {
      try {
        this.batchRecordList = recordList;
        this.destinationRecord = destinationRecord;
        this.batchMoveOperation = moveOperation;

        if (this.isCommitReferDeferBatchOperation(this.batchRecordList)) {
          const sectionId =
            this.destinationRecord.recordTypeId === recordType.SECTION
              ? this.destinationRecord.id
              : this.destinationRecord.ancestor.id;

          await this.loadAgendaSectionItemTemplates(sectionId);
          this.visibleBatchMoveItemsDialog = true;
        } else this.visibleBatchMoveRecordsDialog = true;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Move Record to new location
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onMoveRecordAction() {
      try {
        const operation = findRecordOperation(recordOperation.Move);
        const action = `${operation?.label} ${
          this.sourceRecord?.recordType
        }: '${fullRecordVersionName(this.sourceRecord)}'`;

        if (this.sourceRecord?.isLink ?? false) {
          const payload = {
            recordId: this.sourceRecord?.id ?? -1,
            recordParentId: this.sourceRecord?.ancestor?.id ?? -1,
            destinationId: this.destinationRecord?.id ?? -1
          };
          await this.moveRecordShortcut(payload);
        } else if (this.isMoveByPosition) {
          const payload = {
            recordId: this.sourceRecord?.id,
            destinationId: this.destinationRecord?.id,
            insertTreePosition: this.insertTreePosition
          };

          isBackupMaterial(this.sourceRecord)
            ? await this.moveBackupMaterial(payload)
            : await this.moveMeetingContents(payload);
        } else {
          const payload = {
            recordId: this.sourceRecord?.id,
            destinationId: this.destinationRecord?.id
          };

          await this.moveRecord(payload);
        }

        if (!this.isMoveByPosition) {
          await this.$router.push(
            this.createModuleItemContentRoute(this.destinationRecord?.id)
          );
        } else {
          await this.selectedRangeChanged();
        }

        this.clearSavedRecord();

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Copy Record to new location
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onCopyRecordAction() {
      try {
        const action = `Copy ${
          this.sourceRecord?.recordType
        }: '${fullRecordVersionName(this.sourceRecord)}'`;

        if (this.sourceRecord?.isLink ?? false) {
          const payload = {
            recordId: this.sourceRecord?.id ?? -1,
            recordParentId: this.sourceRecord?.ancestor?.id ?? -1,
            destinationId: this.destinationRecord?.id ?? -1
          };
          await this.copyRecordShortcut(payload);
        } else {
          const payload = {
            recordId: this.sourceRecord?.id,
            destinationId: this.destinationRecord?.id
          };
          await this.copyRecord(payload);
        }

        await this.$router.push(
          this.createModuleItemContentRoute(this.destinationRecord?.id)
        );

        this.clearSavedRecord();

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Event on Batch Move Action Performed
     * @param sourceRecord
     * @param destinationRecord
     * @param moveOperation
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchMoveAction(sourceRecord, destinationRecord, moveOperation) {
      try {
        const action = `${moveOperation} ${
          sourceRecord?.recordType
        }: '${fullRecordVersionName(sourceRecord)}'`;

        const payload = {
          recordId: sourceRecord.id,
          destinationId: destinationRecord.id
        };

        moveOperation === contextMenuRecordOperation.Cut
          ? await this.moveRecord(payload)
          : await this.copyRecord(payload);

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Event on Batch Move Action Performed
     * @param sourceRecord
     * @param destinationRecord
     * @param moveOperation
     * @param option
     * @param templateIndex
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onBatchMoveItemsAction(
      sourceRecord,
      destinationRecord,
      moveOperation,
      option,
      templateIndex
    ) {
      try {
        const action = `${moveOperation} ${
          sourceRecord?.recordType
        }: '${fullRecordVersionName(sourceRecord)}'`;

        const payload = {
          sourceId: sourceRecord?.id ?? -1,
          destinationId: destinationRecord?.id ?? -1,
          option: option,
          index: templateIndex
        };

        console.log("Payload", payload);

        moveOperation === contextMenuRecordOperation.Cut
          ? await this.deferAgendaItem(payload)
          : await this.referAgendaItem(payload);

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Perform Context Menu Action
     * @param {{name: string, record: object}} action
     */

    async performContextMenuAction(action) {
      try {
        console.log(
          `${this.$options.name} performContextMenuAction() action:`,
          action
        );
        const type = action.name;
        const record = action.record;

        switch (type) {
          case contextMenuRecordOperation.Copy: {
            this.clearSavedRecord();

            const payload = {
              type: contextMenuRecordOperation.Copy,
              record: record
            };
            this.setMovedRecord(payload);

            this.showSnackbarSuccess(
              `Successfully Copied ${record.name}`,
              true
            );
            break;
          }

          case contextMenuRecordOperation.Cut: {
            this.clearSavedRecord();

            const payload = {
              type: contextMenuRecordOperation.Cut,
              record: record
            };
            this.setMovedRecord(payload);

            this.showSnackbarSuccess(`Successfully Cut ${record.name}`, true);
            break;
          }

          case contextMenuRecordOperation.Paste: {
            //For Batch
            if (this.movedRecords?.records?.length ?? 0) {
              this.movedRecords.moveType === contextMenuRecordOperation.Cut
                ? await this.prepareBatchMoveRecordsDialog(
                    this.movedRecords.records,
                    record,
                    contextMenuRecordOperation.Cut
                  )
                : await this.prepareBatchMoveRecordsDialog(
                    this.movedRecords.records,
                    record,
                    contextMenuRecordOperation.Copy
                  );
              break;
            } else {
              if (!this.movedRecord.record) return;

              if (this.movedRecord.moveType === contextMenuRecordOperation.Cut)
                await this.prepareMoveRecordDialog(
                  this.movedRecord.record,
                  record
                );
              else {
                await this.prepareCopyRecordDialog(
                  this.movedRecord.record,
                  record
                );
              }
              break;
            }
          }

          case contextMenuRecordOperation.Shortcut: {
            if (!this.movedRecord.record) return;

            if (this.movedRecord?.record?.isLink ?? false) {
              if (this.movedRecord.moveType === contextMenuRecordOperation.Cut)
                await this.prepareMoveRecordDialog(
                  this.movedRecord.record,
                  record
                );
              else {
                await this.prepareCopyRecordDialog(
                  this.movedRecord.record,
                  record
                );
              }
            } else {
              this.sourceRecord = this.movedRecord.record;
              this.destinationRecord = record;
              this.visibleDialogPasteShortcut = true;
            }
            break;
          }

          case contextMenuRecordOperation.JumpOriginal: {
            await this.$router.push(
              this.createModuleItemContentRoute(record.id)
            );
            break;
          }

          case contextMenuRecordOperation.Delete: {
            this.selectedRecord = record;
            this.visibleDialogDelete = true;
            break;
          }

          case contextMenuRecordOperation.SubmitItems: {
            this.selectedRecord = record;
            this.isRecallOperation = false;
            this.visibleSubmitRecallAgendaItemsDialog = true;
            break;
          }

          case contextMenuRecordOperation.RecallItems: {
            this.selectedRecord = record;
            this.isRecallOperation = true;
            this.visibleSubmitRecallAgendaItemsDialog = true;
            break;
          }
        }
      } catch (e) {
        this.showSnackbarError(e, true);
      }
    },

    /**
     * Clear Store Data
     */
    async clearSavedRecord() {
      try {
        const payload = {
          type: undefined,
          record: undefined
        };
        this.setMovedRecord(payload);

        const batchPayload = {
          type: undefined,
          records: []
        };
        this.setMovedRecords(batchPayload);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Delete Record
     * TODO: Test Different Record Types
     * @return {Promise<void>}
     */
    async onRecordDelete() {
      try {
        const parentId = this.selectedRecord?.parentId ?? -1;

        //Verify if parent record is already expanded
        const isParentExpanded =
          this.$router.currentRoute.params?.id?.toString() ===
          parentId.toString();

        if (this.selectedRecord?.isLink ?? false) {
          const payload = {
            id: this.selectedRecord.id ?? -1,
            parentId: parentId
          };
          await this.deleteRecordShortcut(payload);
        } else await this.deleteRecord(this.selectedRecord);

        // Locate parent of deleted record
        if (this.canNavigateToModuleItem(parentId) && !isParentExpanded) {
          await this.$router.push(this.createModuleItemContentRoute(parentId));
        }
      } catch (e) {
        this.showSnackbarError(e, true);
      }
    },

    /**
     * Event On Item Refer
     * @param option
     * @param templateIndex
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onItemRefer(option, templateIndex) {
      try {
        console.log(
          `${this.$options.name} onItemDefer() Insert Option`,
          option
        );
        console.log(
          `${this.$options.name} onItemDefer() templateIndex: `,
          templateIndex
        );

        const operation = findAgendaItemOperation(agendaItemOperation.refer);
        const action = `${operation?.label} ${
          this.sourceRecord?.recordType
        }: '${fullRecordVersionName(this.sourceRecord)}'`;

        const payload = {
          sourceId: this.sourceRecord?.id ?? -1,
          destinationId: this.destinationRecord?.id ?? -1,
          option: option,
          index: templateIndex
        };

        const result = await this.referAgendaItem(payload);

        await this.$router.push(
          this.createModuleItemContentRoute(result?.data?.id)
        );

        this.clearSavedRecord();

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Event On Item Defer
     * @param option
     * @param templateIndex
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onItemDefer(option, templateIndex) {
      try {
        console.log(
          `${this.$options.name} onItemDefer() Insert Option`,
          option
        );
        console.log(
          `${this.$options.name} onItemDefer() templateIndex: `,
          templateIndex
        );

        const operation = findAgendaItemOperation(agendaItemOperation.defer);
        const action = `${operation?.label} ${
          this.sourceRecord?.recordType
        }: '${fullRecordVersionName(this.sourceRecord)}'`;

        const payload = {
          sourceId: this.sourceRecord?.id ?? -1,
          destinationId: this.destinationRecord?.id ?? -1,
          option: option,
          index: templateIndex
        };

        await this.deferAgendaItem(payload);

        await this.$router.push(
          this.createModuleItemContentRoute(this.sourceRecord?.id)
        );

        this.clearSavedRecord();

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * On File Dropped Action
     * @param {{draggedFile: File, destinationRecord: Object}} payload
     */
    async onFileDropped(payload) {
      try {
        if (payload.draggedFile === undefined) {
          this.showSnackbarError("Please Select File Type Only", true);
          return;
        }
        this.draggedFile = payload.draggedFile;
        this.destinationRecord = payload.destinationRecord;

        // If local file is dropped on Folder or an item , set file in local store and route to that record
        if (isDirectUploadAllowed(this.destinationRecord)) {
          this.setLocalUploadedFile(this.draggedFile);
          if (this.canNavigateToModuleItem(this.destinationRecord.id)) {
            await this.$router.push(
              this.createModuleItemContentRoute(this.destinationRecord.id)
            );
          }
        } else this.visibleUploadLocalFileDialog = true;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * On Upload Local File Action
     * @param isInsertFile
     * @param isInsertFileAtBeginning
     * @param  {{isCheckInDraft: boolean, isCheckInOCR : boolean, checkInComment : string}} checkInOption
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onUploadLocalFile(
      isInsertFile,
      isInsertFileAtBeginning,
      checkInOption
    ) {
      try {
        console.log(
          `${this.$options.name} onUploadLocalFile() isInsertFile`,
          isInsertFile
        );
        console.log(
          `${this.$options.name} onUploadLocalFile() isInsertFileAtBeginning: `,
          isInsertFileAtBeginning
        );

        const operation = findImageOperation(imageOperation.Import);
        const action = `${operation?.label} ${
          this.draggedFile?.name
        } to '${fullRecordVersionName(this.destinationRecord)}'`;

        const insertOption = isInsertFile
          ? isInsertFileAtBeginning
            ? insertFileOption.beforeFirstPage
            : insertFileOption.afterLastPage
          : insertFileOption.overwrite;

        const model = UploadOptionModel(
          checkInOption.checkInComment,
          checkInOption.isCheckInOCR,
          checkInOption.isCheckInDraft,
          insertOption
        );

        let result = undefined;
        switch (this.destinationRecord.recordTypeId) {
          case recordType.RECORD: {
            result = await this.uploadFile({
              id: this.destinationRecord?.id ?? -1,
              file: this.draggedFile,
              uploadFileModel: model
            });
            break;
          }
          case recordType.FILE: {
            result = await this.checkInFile({
              id: this.destinationRecord?.id ?? -1,
              file: this.draggedFile,
              uploadFileModel: model
            });
            break;
          }
        }

        //Jump to record that has been updated
        if ((result?.id ?? -1) !== (this.selectedModuleItemId ?? -1)) {
          await this.$router.push(
            this.createModuleItemContentRoute(result?.id)
          );
        }

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Paste Shortcut Event
     * @return {{type: string, message: string, outcome: string}}
     */
    async onPasteShortcutRecordAction() {
      try {
        const action = `Paste Shortcut ${
          this.sourceRecord?.recordType
        }: '${fullRecordVersionName(this.sourceRecord)}'`;

        const payload = {
          recordId: this.sourceRecord?.id,
          destinationId: this.destinationRecord?.id
        };

        await this.pasteRecordShortcut(payload);

        await this.$router.push(
          this.createModuleItemContentRoute(this.destinationRecord?.id)
        );

        this.clearSavedRecord();

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    },

    /**
     * Event called when Load More Records is clicked
     * @return {Promise<void>}
     */
    async loadMoreRecords() {
      try {
        this.loading = true;
        const item = Math.floor(this.items?.length / 10) * 10; // Important: When a selected record is appended to the list without fetch, this code makes sure that it doesn't increase startIndex, which will overwrite other record's place.
        this.startIndex = (item ?? 0) + 1; //Get displayed items. Required so that new items can be appended
        const id =
          this.$router?.currentRoute?.params?.expandedId ?? //This helps us to maintain expanded record even when other record is selected
          this.$router?.currentRoute?.params?.id;
        await this.listItems(id ?? -1);
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Event called when Node range is Changed
     * @return {Promise<void>}
     */
    async selectedRangeChanged() {
      try {
        // Reset to get values from start
        this.startIndex = 1;

        // This helps us to maintain expanded record even when other record is selected
        const params = this.$router?.currentRoute?.params;
        const id = params?.expandedId ?? params?.id ?? -1;

        await this.listItems(id ?? -1);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     *  Close Batch Move Records Dialog
     */
    closeBatchMoveRecordsDialog() {
      this.visibleBatchMoveRecordsDialog = false;
    },

    /**
     *  Close Batch Move Items Dialog
     */
    closeBatchMoveItemsDialog() {
      this.visibleBatchMoveItemsDialog = false;
    },

    /**
     * Close Move Record Dialog
     */
    closeMoveRecordDialog() {
      this.visibleMoveRecordDialog = false;
      this.insertTreePosition = -1;
      this.isMoveByPosition = false;
    },

    /**
     * Is Commit Refer Defer Batch Operation
     * @param {{Array}} batchRecords
     * @return {boolean}
     */
    isCommitReferDeferBatchOperation(batchRecords) {
      for (const record of batchRecords) {
        if (record?.recordTypeId !== recordType.ITEM) {
          return false;
        }
      }
      return true;
    },

    /**
     * Event on Close Submit Recall Agenda Items Dialog
     */
    closeSubmitRecallAgendaItemsDialog() {
      this.isRecallOperation = false;
      this.visibleSubmitRecallAgendaItemsDialog = false;
    },

    /**
     * Event on Items Submit Recall
     * @param operation
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onItemsSubmitRecall(operation) {
      try {
        const action = `${operation?.label} Agenda Item`;

        const id = this.selectedRecord?.id ?? -1;

        const isSubmitOperation =
          operation?.name === agendaItemOperation.submit;

        this.selectedRecord?.recordTypeId === recordType.MEETING
          ? isSubmitOperation
            ? await this.submitMeetingAgendaItems(id)
            : await this.recallMeetingAgendaItems(id)
          : isSubmitOperation
          ? await this.submitSectionAgendaItems(id)
          : await this.recallSectionAgendaItems(id);

        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    }
  },

  watch: {
    /**
     * recordsFetchCount is changed, Load Drawer Children accordingly per new recordsFetchCount
     * @return {Promise<void>}
     */
    async recordsFetchCount() {
      await this.selectedRangeChanged();
    },

    /**
     * Watch if Update Record List is Required
     * @param id
     * @return {Promise<void>}
     */
    async fetchRecordListById(id) {
      try {
        if (
          this.$router.currentRoute.params?.id?.toString() === id?.toString() &&
          !this.$router.currentRoute.params?.expandedId //Make sure the record is expanded in Drawer List
        ) {
          await this.selectedRangeChanged();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.updateRecordListById(undefined);
      }
    },

    async searchDrawerItemName() {
      if (!this.searchDrawerItemName?.length ?? true) {
        this.serverSearchPerformed = false;
        const id =
          this.$router?.currentRoute?.params?.expandedId ?? //This helps us to maintain expanded record even when other record is selected
          this.$router?.currentRoute?.params?.id;
        await this.listItems(id ?? -1);
      }
    }
  }
};
