// mutation-types
import {
  SET_DRAWER_RIGHT,
  SET_USES_APP_TABS,
  SET_USES_SIDEBAR_IMAGE,
  SET_APP_BAR_COLLAPSE_ON_SCROLL,
  SET_DRAWER_ENABLE_MINI_DRAWER,
  SET_COMMAND_BUTTONS_TEXT_STYLE,
  SET_COMMAND_BUTTONS_OUTLINED,
  SET_COMMAND_BUTTONS_ROUNDED,
  SET_COMMAND_BUTTONS_ELEVATION,
  SET_RECORDS_FETCH_COUNT
} from "@/store/solution/mutation-types";

import {
  SET_ENABLE_MODULE_CATEGORY,
  SET_ENABLE_MODULE_TASKS,
  SET_ENABLE_MODULE_PROJECTS,
  SET_ENABLE_MODULE_AGENDA,
  SET_ENABLE_MODULE_SEARCH
} from "@/store/user/mutation-types";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapMutations } = createNamespacedHelpers("solution");

const {
  mapGetters: mapGettersUser,
  mapMutations: mapMutationsUser
} = createNamespacedHelpers("user");

/**
 * User Settings Mixin - encapsulate user Settings functionality
 */
export const userSettingsMixin = {
  computed: {
    ...mapGetters([
      "usesAppTabs",
      "appBarCollapseOnScroll",
      "usesSidebarImage",
      "visibleRightDrawer",
      "enableMiniDrawer",
      "buttonTextStyle",
      "buttonOutlined",
      "buttonRounded",
      "buttonElevation",
      "recordsFetchCount"
    ]),
    ...mapGettersUser([
      "modules",
      "isLicensedModule",
      "enableCategoryModule",
      "enableSearchModule",
      "enableTasksModule",
      "enableProjectsModule",
      "enableAgendaModule",
      "enableWebScan",
      "isEnabledModule"
    ])
  },
  methods: {
    ...mapMutations({
      setUsesAppTabs: SET_USES_APP_TABS,
      setUsesSidebarImage: SET_USES_SIDEBAR_IMAGE,
      setDrawerRight: SET_DRAWER_RIGHT,
      setAppBarCollapseOnScroll: SET_APP_BAR_COLLAPSE_ON_SCROLL,
      setEnableMiniDrawer: SET_DRAWER_ENABLE_MINI_DRAWER,
      setButtonTextStyle: SET_COMMAND_BUTTONS_TEXT_STYLE,
      setButtonOutlined: SET_COMMAND_BUTTONS_OUTLINED,
      setButtonRounded: SET_COMMAND_BUTTONS_ROUNDED,
      setButtonElevation: SET_COMMAND_BUTTONS_ELEVATION,
      setRecordsFetchCount: SET_RECORDS_FETCH_COUNT
    }),
    ...mapMutationsUser({
      setEnableModuleSearch: SET_ENABLE_MODULE_SEARCH,
      setEnableModuleCategory: SET_ENABLE_MODULE_CATEGORY,
      setEnableModuleTasks: SET_ENABLE_MODULE_TASKS,
      setEnableModuleProjects: SET_ENABLE_MODULE_PROJECTS,
      setEnableModuleAgenda: SET_ENABLE_MODULE_AGENDA
    })
  }
};
