import { toLowerCaseSafe } from "@/utils";
import { insertFileOption } from "@/model/record/fileModel";

/**
 * create Check In Option
 * @param {string} comment Check In comment
 * @param {boolean} draft Check In as draft
 * @param {boolean} ocr Optically recognize text of an image
 * @return {{isCheckInDraft: boolean, isCheckInOCR : boolean, checkInComment : string}}
 */
const createCheckInOption = (comment = "", draft = false, ocr = true) => {
  return {
    checkInComment: comment ?? "",
    isCheckInDraft: draft ?? false,
    isCheckInOCR: ocr ?? false
  };
};

/**
 * create CheckIn Model
 * @param {boolean} isFileChanged
 * @param {number} filePageCount
 * @param {boolean} visibleCheckInProgress
 * @param {string} currentError
 * @param {string} checkInExtensionKey
 * @param {string} checkInExtensionValue
 * @return {{currentError: undefined, isFileChanged: boolean, visibleCheckInProgress: boolean, filePageCount: number, checkInExtension: {value: undefined, key: undefined}}}
 */
const createCheckInModel = (
  isFileChanged = false,
  filePageCount = -1,
  visibleCheckInProgress = false,
  currentError = undefined,
  checkInExtensionKey = undefined,
  checkInExtensionValue = undefined
) => {
  return {
    isFileChanged: isFileChanged,
    filePageCount: filePageCount,
    visibleCheckInProgress: visibleCheckInProgress,
    currentError: currentError,
    checkInExtension: {
      key: checkInExtensionKey,
      value: checkInExtensionValue
    }
  };
};

/**
 * Available Check In Option names
 * @type {Readonly<{draft: string, comment: string, ocr: string}>}
 */
const checkInOptionName = Object.freeze({
  comment: "comment",
  draft: "draft",
  ocr: "ocr"
});

/**
 * check In Options
 * @type {({name: string, description: string, label: string})[]}
 */
const checkInOptions = Object.freeze([
  {
    name: checkInOptionName.comment,
    label: "Comment",
    description: "Check In optional comment"
  },
  {
    name: checkInOptionName.draft,
    label: "Draft",
    description: "Check In file as a draft"
  },
  {
    name: checkInOptionName.ocr,
    label: "OCR and Index Recognized Text",
    description:
      "Perform Optical Character Recognition and index recognized text to make text searchable"
  }
]);

/**
 * find Check In Option by its name
 * @param {string} name Check In Option name
 * @return {{name: string, description: string, label: string}}
 */
const findCheckInOption = name =>
  checkInOptions.find(
    el => toLowerCaseSafe(el?.name) === toLowerCaseSafe(name)
  );

/**
 * Create UploadFileCheckInOption Model
 * @param {string|undefined} extension
 * @param {string|undefined} comment
 * @param {boolean} ocr
 * @param {boolean} draft
 * @param {{afterLastPage: number, beforeFirstPage: number, overwrite: number}} insertOption
 * @return {{Extension: (string|undefined), Draft: boolean, InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}, Comments: (string|string), Enqueue: boolean}}
 * @constructor
 */
const UploadFileCheckInOptionModel = (
  extension = undefined,
  comment = "",
  ocr = false,
  draft = false,
  insertOption = insertFileOption.afterLastPage
) => {
  return {
    Extension: extension,
    Comments: comment ?? "",
    Enqueue: ocr,
    Draft: draft,
    InsertFileOption: insertOption
  };
};

/**
 * Create CheckInOption Model
 * @param {string|undefined} extension
 * @param {string|undefined} comment
 * @param {boolean} ocr
 * @param {boolean} draft
 * @return {{Extension: undefined, Draft: boolean, Comments: string, Enqueue: boolean}}
 * @constructor
 */
const CheckInOptionModel = (
  extension = undefined,
  comment = "",
  ocr = false,
  draft = false
) => {
  return {
    Extension: extension,
    Comments: comment,
    Enqueue: ocr,
    Draft: draft
  };
};

/**
 * Create CheckInOption Model
 * @param {string|undefined} comment
 * @param {boolean} ocr
 * @param {boolean} draft
 * @param {{afterLastPage: number, beforeFirstPage: number, overwrite: number}} insertOption
 * @return {{ Draft: boolean, Comments: string, Enqueue: boolean,InsertFileOption: {afterLastPage: number, beforeFirstPage: number, overwrite: number}}}
 */
const UploadOptionModel = (
  comment = "",
  ocr = false,
  draft = false,
  insertOption = insertFileOption.afterLastPage
) => {
  return {
    Comments: comment,
    Enqueue: ocr,
    Draft: draft,
    InsertFileOption: insertOption
  };
};

export {
  createCheckInOption,
  createCheckInModel,
  checkInOptionName,
  checkInOptions,
  findCheckInOption,
  CheckInOptionModel,
  UploadFileCheckInOptionModel,
  UploadOptionModel
};
